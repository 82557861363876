@import "bootstrap/dist/css/bootstrap.min.css";

$backgroundColor: rgb(179, 154, 47);

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::before {
  content: "";
  position: fixed;
  top: -10px;
  left: 0;
  width: 100%;
  height: 10px;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.8);
  z-index: 100;
}

html {
  background: url(images/woodBackground.jpeg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  // background-color: $backgroundColor;
  // background-blend-mode: screen;

  body {
    background: inherit;
    //background-color: $backgroundColor;
    //background-blend-mode: screen;
  }
}

.header {
  background-color: white;
  padding: 0 10px 8px 0;
  display: flex;
  justify-content: space-between;

  h1 {
    color: rgb(158, 0, 0);
    font-family: "Caveat", cursive;
    font-weight: 700;
    font-size: 40pt;
    margin-bottom: 0;
  }

  .contact-info {
    font-size: 14pt;
    font-weight: 600;
    margin: 8px;
  }
}

.banner {
  height: 200px;
  background: url(images/truck.jpg) no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  z-index: 100;
}

.catch-phrase {
  margin: 20px;
  margin: 0 0 50px 0;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .col {
    display: flex;
    justify-content: center;
  }
}

.products {
  margin-top: 20px;

  .product-row {
    margin-top: 20px;

    .product-image-col {
      display: flex;
      justify-content: center;

      .product-image {
        max-width: 100%;
        max-height: 300px;
        border-radius: 20px;
      }
    }
  }
}

.product-columns {
  margin-top: 20px;

  .product-column {
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    padding-bottom: 20px;
    margin-bottom: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);

    .price {
      color: green;
      font-size: 14pt;
      font-weight: 600;
    }
  }

  .product-image-col {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .product-image {
      max-width: 100%;
      border-radius: 20px;
    }
  }
}

.contact-footer {
  background-color: white;
  padding-top: 20px;
  padding-bottom: 90px;
  height: 600px;

  #order-detail {
    height: 100px;
  }
}

@media (min-width: 576px) {
  .banner {
    height: 400px;
  }
}
